/**
 * 品牌馆-品牌故事
 * luxinwen
 * 2023-04-17
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">{{ formData.title }}</div>
        <div class="padding-b-20">
          <sp-editor v-model="formData.mainContent"></sp-editor>
        </div>
      </div>
    </div>
    <sp-footer>
      <Button type="primary" :loading="btnLoading" @click="submit">提交</Button>
      <Button @click="cancel">返回</Button>
    </sp-footer>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        btnLoading: false,  // 按钮状态
        formData: {
          id: '',
          title: '品牌故事',
          mainContent: ''
        }
      };
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          tags: 'story',
          pageNum: 1,
          pageSize: 10
        });
        this.$axios({
          url: this.$api.news.queryNewsList,
          data,
          loading: true
        }).then(data => {
          let list = data.list || [];
          if (list.length > 0) {
            let item = list[0];
            this.formData.id = item.id;
            this.formData.mainContent = item.mainContent;
          }
        });
      },
      /**
       * 提交
       */
      submit() {
        this.$confirm('确定要提交吗？').then(() => {
          let url = this.$api.news.addNews;
          let data = Object.assign({}, this.formData);
          data.tags = 'story';
          if (data.id) {
            url = this.$api.news.updateNews;
          } else {
            delete data.id;
          }
          this.save(url, data);
        });
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.btnLoading = true;
          },
          complete: () => {
            this.btnLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '提交成功'
          });
        });
      },
      /**
       * 返回
       */
      cancel() {
        this.$router.go(-1);
      }
    }
  };
</script>